<script setup>
    import VueDatePicker from '@vuepic/vue-datepicker';
    import { formatDate } from '@/helpers.js';
    import {computed, ref} from "vue";
    import {useI18n} from "vue-i18n";

    const props = defineProps({
        min: undefined,
        position: {
            type: String,
            default: 'right',
        },
        options: {
            type: Object,
            default: () => ({
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            }),
        },
        name: {
            type: String,
            default: () => 'date'
        },
        availability: {
            type: Object,
            default: () => {}
        }
    })

    const date = ref(new Date(props.min))

    const i18n = useI18n()
    const emit = defineEmits(['change'])

    const flow = ref(['calendar', 'time'])

    const format = (date) => {
        return formatDate(date, props.options, i18n?.locale?.value);
    }
    const changeDate = () => {
        emit('change', date)
    }
    // INIT the date
    changeDate()

    const disabledDates = computed({
        get: () => {
            let disabledDates = []

            for(const date in props.availability) {
                if(props.availability[date] === 0)  {
                    disabledDates.push(date)
                }
            }

            return disabledDates;
        }
    })

</script>

<template>
    <section class="w-full text-xs input__date-picker">
        <VueDatePicker
            ref="datePicker"
            v-model="date"
            :name="name"
            :flow="flow"
            minutes-increment="15"
            minutes-grid-increment="15"
            :format="format"
            :clearable="false"
            :min-date="min"
            :year-range="[2024, 2025]"
            :action-row="{showPreview: false, showCancel: false}"
            @closed="changeDate"
        >
            <template #action-row="{ selectDate }">
                <div class="action-row w-full">
                    <button type="button" class="w-full btn btn-primary" @click="selectDate">Bestätigen</button>
                </div>
            </template>

        </VueDatePicker>
    </section>
</template>
