<script setup>
import {Head, useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Layout.vue';
import DatePicker from "@/Components/DatePicker.vue";
import { Loader } from "@googlemaps/js-api-loader";
import { CreditCardIcon, KeyIcon, CheckCircleIcon } from "@heroicons/vue/24/outline/index.js";
import { formatValue } from "../helpers.js";
import {computed, ref} from "vue";

defineOptions({
    layout: Layout,
});

defineProps({
    products: Array,
    availability: Object,
});

const loader = new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    version: "weekly",
});

loader.load().then(async () => {
    let AdvancedMarkerElement
    const { Map } = await google.maps.importLibrary("maps");
    ({ AdvancedMarkerElement } = await google.maps.importLibrary("marker"));

    const map = new Map(document.getElementById("map"), {
        mapId: '97626b319e43743d',
        mapTypeId: "roadmap",
        center: {
            lat: 47.384971618652344, lng: 11.268280029296875
        },
        zoom: 16,
        streetViewControl: false
    });

    new AdvancedMarkerElement({
        position: { lat: 47.384971618652344, lng: 11.268280029296875 },
        map: map,
        title: "Parkplatz Scharnitz"
    })

});

const form = useForm({
    reservationDate: new Date(),
})

const changeReservationDate = (date) => {
    form.reservationDate = date.value
}

const currencyOptions = {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0
}
const productPriceFormatted = (amount) => {
    return formatValue(amount / 100, currencyOptions)
}

const faqArray = [
    {
        "question": "Kann ich auch vor Ort einen Parkschein lösen?",
        "answer": "Ja, du kannst vor Ort einfach online buchen. Barzahlung ist nicht möglich."
    },
    {
        "question": "Ist mir ein Parkplatz zugesichert?",
        "answer": "Ja! Sobald du eine Bestätigung von uns erhalten hast, ist dir ein Parkplatz garantiert."
    },
    {
        "question": "Was tun, wenn etwas mit meiner Reservierung schief gegangen ist?",
        "answer": "Keine Sorge! Einfach per Mail unter office@parkplatz-scharnitz.at kontaktieren."
    },
    {
        "question": "Ist das Parkticket auch beim Informationszentrum in Scharnitz gültig?",
        "answer": "Nein. Der Parkplatz gehört nicht zur Gemeinde Scharnitz und deshalb ist auch das Ticket dort nicht gültig. Bitte parken Sie an der richtigen Adresse."
    },
    {
        "question": "Woher bekomme ich eine Rechnung?",
        "answer": "Schreib uns einfach eine Mail mit deinen Daten und wir senden dir eine Rechnung."
    },
]

const tipps = [
    {
        title: "Gleierschklamm",
        description: "Ein wunderschöner Spaziergang der nicht zu anspruchsvoll ist!",
        badges: ["Wandern", "Leicht", "~2h"],
        image: "/assets/klamm-foto.jpeg"
    },
    {
        title: "Hallerangeralm",
        description: "Eine traumhafte bewirtschaftete Almhütte mit Schlafmöglichkeiten.",
        badges: ["Wandern & Radfahren", "Mittel", "~6h"],
        image: "/assets/halleranger-alm.jpeg"
    },
    {
        title: "Karwendelhaus",
        description: "Die Hütte bietet den optimalen Ausgangspunkt für anspruchsvolle Bergtouren.",
        badges: ["Wandern & Radfahren", "Leicht", "~4,5h"],
        image: "/assets/karwendel-haus.jpg"
    },
]

const comeFromGermany = ref(true)

const reviews = [
    {
        text: "Tolle Parkmöglichkeit! Hat sehr einfach funktioniert.",
        image: "/assets/portrait-1.jpg"
    },
    {
        text: "Super einfach und unkompliziert. Unsere Wanderung danach war traumhaft!",
        image: "/assets/portrait-2.jpg"
    },
    {
        text: "Wir konnten schon im Voraus buchen und mussten uns keine Gedanken mehr machen.",
        image: "/assets/portrait-3.jpg"
    },
]

const minDate = computed(() => {
    let minDate = new Date();

    const minutes = minDate.getMinutes();
    const minutesToSubtract = (minutes % 15);
    minDate.setMinutes(minutes - minutesToSubtract);
    minDate.setSeconds(0);

    return minDate;
})

</script>

<template>
    <div>
        <Head title="Parken in Scharnitz | Jetzt online buchen | Hinterautalstraße">
            <meta name="description" content="Jetzt schnell und einfach einen Parkplatz in Scharnitz online buchen! Ausflug in's Karwendel oder Hinterautal genießen. Ganz entspannt und sorgenfrei.">
            <meta name="robots" content="index, follow">
            <meta property="og:title" content="Parken in Scharnitz | Jetzt online buchen | Hinterautalstraße" />
            <meta property="og:description" content="Jetzt schnell und einfach einen Parkplatz in Scharnitz online buchen! Ausflug in's Karwendel oder Hinterautal genießen. Ganz entspannt und sorgenfrei." />
            <meta name="twitter:title" content="Parken in Scharnitz | Jetzt online buchen | Hinterautalstraße" />
            <meta name="twitter:description" content="Jetzt schnell und einfach einen Parkplatz in Scharnitz online buchen! Ausflug in's Karwendel oder Hinterautal genießen. Ganz entspannt und sorgenfrei." />
        </Head>
        <div class="absolute top-16 left-0 w-full h-[32rem] md:h-[44rem] lg:h-[50rem] -z-10">
            <span class="bg-none absolute top-0 left-0 w-full h-full">
                <img class="w-full h-full object-cover opacity-80" alt="Hiking map" src="/assets/bg-hero.png" />
            </span>
        </div>
        <div id="#booking" class="container pt-16 mx-auto">
            <section class="w-full flex flex-col justify-center items-center h-[32rem] md:h-[44rem] lg:h-[50rem]">
                <h1 class="text-3xl font-black uppercase text-center">
                    Parkplatz online reservieren <br>in Scharnitz
                </h1>
                <form @submit.prevent="form.get('/reservation')">
                    <div class="mt-16 space-y-4 flex flex-col justify-center">
                        <h3 class="text-lg uppercase font-bold text-center">Wann kommst Du an?</h3>
                        <DatePicker
                            v-model="form.reservationDate"
                            :min="minDate"
                            :availability="availability"
                            @change="changeReservationDate"
                        />

                        <button
                            type="submit"
                            :disabled="form.processing"
                            class="btn btn-primary"
                        >
                            Weiter zur Buchung
                        </button>
                    </div>
                </form>
            </section>


            <section class="mt-12 md:mt-32 md:px-10">
                <h3 class="text-center uppercase font-bold">
                    Wie funktioniert's?
                </h3>
                <div class="mt-8 grid grid-cols-1 sm:grid-cols-3 gap-4 text-center mx-8">
                    <div class="flex flex-col items-center">
                        <CreditCardIcon class="size-1/3" />
                        <h4 class="mt-4 font-bold">Online buchen & bezahlen</h4>
                        <p class="mt-2">Reservier deinen Parkplatz im Voraus und bezahle sehr einfach hier.</p>
                    </div>
                    <div class="flex flex-col items-center">
                        <KeyIcon class="size-1/3" />
                        <h4 class="mt-4 font-bold">Kommen & gehen</h4>
                        <p class="mt-2">Einfach kommen, parken und auch wieder fahren. Es gibt keine fixen Zeiten.</p>
                    </div>
                    <div class="flex flex-col items-center">
                        <CheckCircleIcon class="size-1/3" />
                        <h4 class="mt-4 font-bold">Mit einem guten Gefühl!</h4>
                        <p class="mt-2">Es ist wirklich so einfach. Du musst einfach nur den Ausflug genießen.</p>
                    </div>
                </div>
            </section>

            <section class="text-center mt-12 md:mt-32 mx-8">
                <h3 class="uppercase font-bold">
                    Wo finde ich den Parkplatz Hinterautalstraße in Scharnitz?
                </h3>
                <div class="my-3 flex justify-center gap-4 align-center">
                    <button class="btn btn-sm" :class="comeFromGermany ? 'btn-primary' : 'btn-primary-outline'" @click="comeFromGermany = true">Von Deutschland</button>
                    <button class="btn btn-sm" :class="!comeFromGermany ? 'btn-primary' : 'btn-primary-outline'" @click="comeFromGermany = false">Von Innsbruck</button>
                </div>
                <p class="text-center md:px-20 xl:px-40">
                    {{comeFromGermany ? 'Vor der Kirche in Scharnitz links reinfahren' : 'Nach der Kirche in Scharnitz rechts abbiegen'}}, der Straße ca. 700 Meter folgen, vorbei an Holzbau Sprenger bis eine leichte Steigung kommt.
                    Auf der rechten Seite findest du dann schon das Schild Parkplatz Hinterautalstraße.
                </p>
                <div class="mt-8 w-full flex justify-center">
                    <div id="map" class="w-full h-96 rounded-xl"></div>
                </div>
            </section>


            <section class="text-center mt-12 md:mt-32">
                <h3 class="uppercase font-bold">
                    Parkdauer & Preise
                </h3>
                <div class="mt-8 grid grid-cols-2 md:grid-cols-4 gap-4 mx-8">
                    <div v-for="(product, index) in products" :key="index" class="card border border-solid border-neutral text-primary h-60">
                        <div class="card-body items-center justify-center text-center">
                            <h2 class="card-title flex-col">
                                <span class="text-4xl md:text-5xl">{{productPriceFormatted(product.amount)}}</span>
                                <span class="md:text-xl font-normal">{{product.name}}</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <button
                        type="button"
                        class="btn btn-primary"
                    >
                        <a href="#booking">
                            Jetzt Parkplatz reservieren
                        </a>
                    </button>
                </div>
            </section>

            <section class="text-center mt-12 md:mt-32">
                <h3 class="uppercase font-bold">
                    Das sagen unsere Besucher
                </h3>
                <div class="mt-4 mx-8 grid grid-cols-1 lg:grid-cols-3 gap-4">
                    <div v-for="(review, index) in reviews" :key="index" class="card border border-primary/20 shadow-xl text-primary col-span-1">
                        <div class="card-body">
                            <h2 class="card-title justify-center">
                                <div class="rating">
                                    <input type="radio" disabled class="mask mask-star cursor-default bg-[#daa520]" />
                                    <input type="radio" disabled class="mask mask-star cursor-default bg-[#daa520]" />
                                    <input type="radio" disabled class="mask mask-star cursor-default bg-[#daa520]" />
                                    <input type="radio" disabled class="mask mask-star cursor-default bg-[#daa520]" />
                                    <input type="radio" disabled class="mask mask-star cursor-default text-[#daa520]" checked="checked" />
                                </div>
                            </h2>
                            <div class="mt-4 flex align-center gap-x-4">
                                <div class="avatar">
                                    <div class="w-16 h-16 rounded-full">
                                        <img :src="review.image" />
                                    </div>
                                </div>
                                <p class="text-left">{{review.text}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="text-center mt-12 md:mt-32">
                <h3 class="uppercase font-bold">
                    Du benötigst Tipps zum Wandern oder Radfahren?
                </h3>
                <div class="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mx-8">
                    <div v-for="(tip, index) in tipps" :key="index" class="card bg-base-100 shadow-xl">
                      <figure class="h-60">
                          <img :src="tip.image" alt="Shoes" />
                      </figure>
                      <div class="card-body">
                        <h2 class="card-title justify-center">
                          {{tip.title}}
                        </h2>
                        <p>{{tip.description}}</p>
                        <div class="card-actions justify-center">
                            <div v-for="(badge, i) in tip.badges" :key="i" class="badge badge-outline">{{badge}}</div>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
            <section class="text-center mt-12 md:mt-32">
                <h3 class="uppercase font-bold">
                    Häufig gestellte Fragen (FAQ)
                </h3>
                <div class="mt-8 space-y-4">
                    <div v-for="(faq, index) in faqArray" :key="index" class="join join-vertical w-full">
                        <div class="collapse collapse-arrow join-item border border-base-300">
                            <input class="w-full" type="radio" name="faq-section" />
                            <div class="collapse-title text-xl font-medium">
                                {{faq.question}}
                            </div>
                            <div class="collapse-content">
                                <p>{{faq.answer}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="text-center my-32">
                <h3 class="text-2xl font-bold">
                    Du willst für deinen Ausflug jetzt einen Parkplatz reservieren?
                </h3>

                <div class="mt-4">
                    <a href="#booking">
                        <button
                            type="button"
                            class="btn btn-primary"
                        >
                                Zur Buchung
                        </button>
                    </a>
                </div>
            </section>
        </div>
    </div>
</template>
